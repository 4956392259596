@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari, and Opera */
      .no-scrollbar::-webkit-scrollbar {
        display: none;
        overflow: hidden;
      }
  
  
      /* Hide scrollbar for IE, Edge, and Firefox */
      .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        overflow: hidden;
      }
    }
  } 